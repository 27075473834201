<template>
  <div class="d-flex flex-column">
    <module-navigation-bar title="Pick order management">
      <template #module-nav-logo>
        <img
          v-if="isDaiwaLicense"
          alt=""
          height="40px"
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
        />
      </template>
    </module-navigation-bar>
    <div
      v-if="!$wait.is('dhme.pick-order-management.get')"
      class="flex-1 overflow-hidden d-flex"
    >
      <panel-resizable
        :default-width="600"
        :min-width="100"
        class="ant-glass-background radius-0 ant-border-right"
        side="left"
      >
        <div class="d-flex flex-column">
          <v-subheader
            >Pick orders
            <v-spacer />
            <v-icon class="ant-icon" @click="displayPickOrderDialog = true"
              >mdi-plus
            </v-icon>
            <add-pick-order-dialog
              ref="pick-order-dialog"
              :displayed="displayPickOrderDialog"
              @closeDialog="displayPickOrderDialog = false"
            />
          </v-subheader>

          <pick-order-item
            v-for="task in dhmePickOrderManagementTasks"
            :key="task.id"
            :halls="dhmePickOrderManagementAssemblyHalls"
            :locations="dhmePickOrderManagementStorageLocations"
            :task="task"
            class="ant-border-bottom"
            @click="searchPickOrderItem(task.task_type.custom_2)"
          />
        </div>
      </panel-resizable>
      <div class="d-flex pa-5">
        <div class="d-flex flex-column">
          <div>
            <v-text-field
              v-model="searchElement"
              :loading="searchLoading"
              append-outer-icon="mdi-qrcode"
              clearable
              dense
              filled
              flat
              hide-details
              placeholder="Type here to search"
              prepend-inner-icon="mdi-magnify"
              @keydown.enter="searchStoredElement"
              @click:clear="selectedBox = null"
              @click:append-outer="searchQrDialog = true"
            />
            <v-dialog
              key="search-pick-order-element-scanner"
              v-model="searchQrDialog"
              max-width="500px"
            >
              <base-ant-scanner @decodedResult="searchPickOrderItem" />
            </v-dialog>
          </div>
          <div class="d-flex">
            <ant-input class="mr-4" label="Location">
              <template #input-field>
                <v-select
                  v-model="selectedLocation"
                  :items="locations"
                  :rules="[rules.required]"
                  dense
                  filled
                  hide-details
                  item-value="id"
                  placeholder="location"
                  return-object
                  single-line
                >
                  <template #selection="{ item }">
                    <v-icon class="mr-2">
                      {{
                        isAssemblyHall(item)
                          ? 'mdi-warehouse'
                          : 'mdi-file-table-box'
                      }}
                    </v-icon>
                    {{ isAssemblyHall(item) ? item.hall : item.name }}
                  </template>
                  <template #item="{ item }">
                    <div class="d-flex align-center">
                      <v-icon class="mr-2">
                        {{
                          isAssemblyHall(item)
                            ? 'mdi-warehouse'
                            : 'mdi-file-table-box'
                        }}
                      </v-icon>
                      {{ isAssemblyHall(item) ? item.hall : item.name }}
                    </div>
                  </template>
                </v-select>
              </template>
            </ant-input>
            <ant-input label="Level">
              <template #input-field>
                <v-select
                  v-model="selectedLevel"
                  :disabled="!selectedLocation"
                  :items="locationLevels"
                  dense
                  filled
                  placeholder="Level 1"
                  single-line
                />
              </template>
            </ant-input>
          </div>

          <div v-if="selectedLocation">
            <storage-location-level-layout
              v-if="selectedLevel"
              :level-items="levelStoredItems"
              :location-level="selectedLevel"
              :selected-box="selectedBox"
              :storage-boxes="dhmePickOrderManagementStorageBoxes"
              :storage-location="selectedLocation"
              @selectStorageBox="selectStorageBox"
            />

            <div v-if="selectedBox">
              <v-subheader>Stored items</v-subheader>

              <div
                v-for="item in storageBoxItems"
                :key="item.id"
                :class="{
                  'searched-element': item.item.includes(searchElement),
                }"
                class="d-flex white align-center py-2 px-4 mb-2"
              >
                <div class="font-italic">{{ item.type }}</div>
                <v-divider class="mx-4" vertical />
                <div>{{ item.item }}</div>
                <v-spacer />

                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      @click="requestTransport(item)"
                      v-on="on"
                    >
                      <v-icon class="ant-icon">mdi-view-grid-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Request transport</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex-grow-1 d-flex align-center justify-center">
      <ant-loading />
    </div>
  </div>
</template>

<script>
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import { mapGetters } from 'vuex';
import AntLoading from '@/components/AntLoading.vue';
import { DHME_M_PICK_ORDER_MANAGEMENT } from '@/modules/modules';
import PanelResizable from '@/components/Project/PanelResizable.vue';
import AddPickOrderDialog from '@/components/Modules/Daiwa-House-Modular-Europe/PickOrderAndYardManagement/AddPickOrderDialog.vue';
import moment from 'moment';
import PickOrderItem from '@/components/Modules/Daiwa-House-Modular-Europe/PickOrderAndYardManagement/PickOrderItem.vue';
import AntInput from '@/components/AntInput.vue';
import PickOrderManagementMixin from '@/components/Modules/Daiwa-House-Modular-Europe/PickOrderAndYardManagement/PickOrderManagementMixin';
import InputRulesMixin from '@/Mixins/InputRulesMixin';
import StorageLocationLevelLayout from '@/components/Modules/Daiwa-House-Modular-Europe/PickOrderAndYardManagement/StorageLocationLevelLayout.vue';
import { executeCustomModuleCall } from '@/services/api/module.api';
import BaseAntScanner from '@/components/Base/BaseAntScanner.vue';

export default {
  name: 'DHMEMPickOrderManagement',
  components: {
    BaseAntScanner,
    StorageLocationLevelLayout,
    AntInput,
    PickOrderItem,
    AddPickOrderDialog,
    PanelResizable,
    AntLoading,
    ModuleNavigationBar,
  },
  mixins: [PickOrderManagementMixin, InputRulesMixin],
  data: () => {
    return {
      displayPickOrderDialog: false,
      selectedLocation: null,
      selectedLevel: null,
      selectedBox: null,
      levelStoredItems: [],
      searchElement: null,
      searchResult: null,
      searchLoading: false,
      searchQrDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'dhmePickOrderManagementTasks',
      'dhmePickOrderManagementAssemblyHalls',
      'dhmePickOrderManagementStorageLocations',
      'dhmePickOrderManagementStorageBoxes',
      'isDaiwaLicense',
    ]),
    moduleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_M_PICK_ORDER_MANAGEMENT
      ).id;
    },
    locationLevels() {
      return [
        ...new Set(
          this.dhmePickOrderManagementStorageBoxes
            .filter(
              (item) => item.storage_location === this.selectedLocation?.id
            )
            .map((item) => item.level)
        ),
      ].sort((a, b) => a - b);
    },
    now() {
      return moment();
    },
    storageBoxItems() {
      return this.levelStoredItems.filter(
        (item) => item.storage_box === this.selectedBox?.id
      );
    },
  },
  watch: {
    selectedLevel(value) {
      if (value) {
        this.selectedBox = null;
        this.fetchLocationLevelElements();
      }
    },
  },
  mounted() {
    this.$store.dispatch('fetchDhmePickOrderManagementData', {
      moduleId: this.moduleId,
      sessionId: this.$route.params.sessionId ?? null,
    });

    this.$store.dispatch('fetchDhmePickOrderTasks', {
      moduleId: this.moduleId,
    });
  },
  methods: {
    searchPickOrderItem(item) {
      this.selectedBox = null;
      this.selectedLevel = null;
      this.selectedLocation = null;
      this.searchElement = item;
      this.searchStoredElement();
    },
    selectStorageBox(item) {
      this.selectedBox = item;
    },
    async fetchLocationLevelElements() {
      this.levelStoredItems = await executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'fetchLocationLevelStoredElements',
        {
          location: this.selectedLocation.id,
          level: this.selectedLevel,
        }
      );
    },
    requestTransport(item) {
      this.displayPickOrderDialog = true;
      this.$refs['pick-order-dialog'].pickOrderItem.options.custom_1 =
        item.type;
      this.$refs['pick-order-dialog'].pickOrderItem.options.custom_2 =
        item.item;
      this.$refs['pick-order-dialog'].pickOrderItem.options.custom_3 =
        this.selectedLocation.id;
      this.$refs['pick-order-dialog'].pickOrderItem.project = item.project;
    },
    async searchStoredElement() {
      this.searchLoading = true;
      this.searchResult = await executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'searchStoredElement',
        {
          element: this.searchElement,
        }
      );
      this.searchLoading = false;
      this.selectedLocation = this.dhmePickOrderManagementStorageLocations.find(
        (item) => item.id === this.searchResult.storage_location
      );
      this.selectedLevel = this.searchResult.level;
      this.$nextTick(() => {
        this.selectedBox = this.dhmePickOrderManagementStorageBoxes.find(
          (item) => item.id === this.searchResult.storage_box
        );
      });
      this.searchQrDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.searched-element {
  transform: translateX(10px);
}
</style>
