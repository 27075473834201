<template>
  <div class="d-flex flex-column c-pointer" @click="$emit('click', task)">
    <div class="d-flex px-4 py-2">
      <div class="mr-4 d-flex align-center justify-center">
        <v-chip
          v-if="task.status === 'open'"
          :color="
            getDaysLeft(task) < 0
              ? 'error'
              : getDaysLeft(task) === 0
                ? 'info'
                : 'success'
          "
          small
        >
          {{ getDaysLeft(task) }}
        </v-chip>
        <v-icon v-else color="success"> mdi-check-circle</v-icon>
      </div>

      <div class="d-flex flex-column">
        <div class="fs-20 font-weight-bold d-flex align-center">
          {{ task.task_type.custom_1 }}
          <span class="fs-16 ml-2 font-italic font-weight-regular">{{
            task.task_type.custom_2
          }}</span>
        </div>
        <div>{{ task.description }}</div>
      </div>

      <v-spacer />

      <div class="d-flex flex-column px-2">
        <div class="d-flex align-center">
          <v-icon class="mr-2">
            {{
              getHallOrLocation(task.task_type.custom_3).hall
                ? 'mdi-warehouse'
                : 'mdi-file-table-box'
            }}
          </v-icon>
          {{
            getHallOrLocation(task.task_type.custom_3).hall
              ? getHallOrLocation(task.task_type.custom_3).hall
              : getHallOrLocation(task.task_type.custom_3).name
          }}
        </div>
        <div class="ml-2 d-flex align-center">
          <v-icon>mdi-arrow-right-bottom</v-icon>
          <v-icon class="mr-2">
            {{
              getHallOrLocation(task.task_type.custom_4).hall
                ? 'mdi-warehouse'
                : 'mdi-file-table-box'
            }}
          </v-icon>
          {{
            getHallOrLocation(task.task_type.custom_4).hall
              ? getHallOrLocation(task.task_type.custom_4).hall
              : getHallOrLocation(task.task_type.custom_4).name
          }}
        </div>
      </div>

      <div class="d-flex">
        <v-divider class="mx-2" vertical />
        <v-icon class="ant-icon" @click="deleteDialog = true"
          >mdi-delete
        </v-icon>
        <delete-dialog
          :dialog="deleteDialog"
          title="Are you sure you want to delete this pickorder"
          @closeDialog="deleteDialog = false"
          @deleteAction="deleteTask"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment/moment';
import DeleteDialog from '@/components/DeleteDialog.vue';

export default {
  name: 'PickOrderItem',
  components: { DeleteDialog },
  props: {
    task: {
      type: Object,
      required: true,
    },
    halls: {
      type: Array,
      default: () => [],
    },
    locations: {
      type: Array,
      default: () => [],
    },
  },
  data: () => {
    return {
      deleteDialog: false,
    };
  },
  methods: {
    getHallOrLocation(id) {
      let item = this.halls.find((item) => item.id === id);
      if (item) {
        return item;
      }
      return this.locations.find((item) => item.id === id);
    },
    getDaysLeft(task) {
      return moment(task.due).diff(this.now, 'days');
    },
    deleteTask() {
      this.$store.dispatch('deletePickOrderTask', [
        {
          id: this.task.id,
          method: 'single',
        },
      ]);
    },
  },
};
</script>

<style scoped></style>
